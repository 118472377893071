.form{
	margin:0 auto;
	max-width:670px;
	margin-top:115px;

	@media (max-width:$screen-sm - 1px){
		margin-top:50px;
	}


	&__container{
		margin:0 -15px;

		@media (max-width:$screen-sm - 1px){
			margin:0;
		}
	}

	&__col{
		float:left;
		width:50%;
		padding:0 15px;

		@media (max-width:$screen-sm - 1px){
			width:100%;
			float:none;
			padding:0;
		}
	}

	&__block{
		padding:0 15px;
		text-align:center;
		

		@media (max-width:$screen-sm - 1px){
			padding:0;
		}
	}

	&__input{
		width:100%;	
		border:1px solid $color2;
		font-size:15px;
		padding:0 10px;
		height:38px;
		margin-bottom:15px;
		outline:none;
		
		&::placeholder{
			color:$color3;
		}
	}

	&__textarea{
		border:1px solid $color2;
		font-size:15px;
		padding: 5px 10px;
		min-height:145px;
		max-height:145px;
		min-width:320px;
		max-width:320px;
		outline:none;
	
		@media (max-width:$screen-sm - 1px){
			max-width:100%;
			width:100%;
			margin-bottom:15px;
		}

		&::placeholder{
			color:$color3;
		}
	}

	&__text{
		font-size:10px;
		color:$color5;
		text-align:center;
		margin-top:10px;
	}

	&__center{
		text-align:center;
	}

	&__button{
		border:0;
		outline:0;
		display:inline-block;
		background:$color7;
		min-width: 270px;
		color:$white;
		font-size:15px;
		text-transform: uppercase;
		font-weight:bold;
		text-align: center;
		margin:0 auto;
		margin-top:20px;
		padding: 13px 20px;
    	border-radius: 40px;
    	-webkit-transition: box-shadow 0.3s;
    	   -moz-transition: box-shadow 0.3s;
    	    -ms-transition: box-shadow 0.3s;
    	     -o-transition: box-shadow 0.3s;
    	        transition: box-shadow 0.3s;

    	&:hover,&:focus{
    		color:$white;
    		text-decoration:none;
    	}

    	&:hover{
    		box-shadow: 0 0 30px 3px $color7;
    	}

	}

	&__label{
		font-weight:400;
		font-size:10px;
		color:$color10;
		margin-bottom:10px;
		text-align: justify;
	}

	&__checkbox{
		float:left;
		margin-right:7px !important;
		margin:1px 0 0 !important;
	}

	&__more{
		font-weight:bold;
		color:$color10;

		&:hover,&:focus{
			color:$color10;
		}
	}

	&__moreText{
		display:none;
	}
}