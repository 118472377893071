.sprite { background: url('/media/default/sprites.png') no-repeat top left;  } 
.sprite.phone-circle { background-position: 0px 0px; width: 60px; height: 60px;  } 
.sprite.phones-w { background-position: 0px -70px; width: 22px; height: 20px;  } 
.sprite.phone-w { background-position: 0px -100px; width: 32px; height: 30px;  } 
.sprite.badge-c { background-position: 0px -140px; width: 74px; height: 42px;  } 
.sprite.badge-w { background-position: 0px -192px; width: 74px; height: 42px;  } 
.sprite.calendar-w { background-position: 0px -244px; width: 22px; height: 22px;  } 
.sprite.fb { background-position: 0px -276px; width: 31px; height: 30px;  } 
.sprite.inst-w { background-position: 0px -316px; width: 31px; height: 30px;  } 
.sprite.fb-w { background-position: 0px -356px; width: 31px; height: 30px;  } 
.sprite.footer-fb { background-position: 0px -396px; width: 31px; height: 30px;  } 
.sprite.footer-google { background-position: 0px -436px; width: 31px; height: 30px;  } 
.sprite.footer-inst { background-position: 0px -476px; width: 31px; height: 30px;  } 
.sprite.footer-yt { background-position: 0px -516px; width: 32px; height: 30px;  } 
.sprite.icon1 { background-position: 0px -556px; width: 33px; height: 39px;  } 
.sprite.icon2 { background-position: 0px -605px; width: 40px; height: 23px;  } 
.sprite.icon3 { background-position: 0px -638px; width: 32px; height: 36px;  } 
.sprite.icon4 { background-position: 0px -684px; width: 36px; height: 29px;  } 
.sprite.icon5 { background-position: 0px -723px; width: 38px; height: 36px;  } 
.sprite.icon6 { background-position: 0px -769px; width: 28px; height: 38px;  } 
.sprite.inst { background-position: 0px -817px; width: 31px; height: 30px;  } 
.sprite.marker { background-position: 0px -857px; width: 19px; height: 26px;  } 
.sprite.marker-w { background-position: 0px -893px; width: 19px; height: 26px;  } 
.sprite.optimalit { background-position: 0px -929px; width: 89px; height: 12px;  } 
.sprite.phone { background-position: 0px -951px; width: 22px; height: 20px;  } 