.footer{
	background:$color6;
	padding: 148px 15px;
    padding-bottom: 105px;
    position:fixed;
    width:100%;
    bottom:0;
    z-index:0;
    display:none;

    @media (max-width:$screen-md - 1px){
		padding:40px 15px;
		position:static;
		display:block;
	}

    &.show{
    	display:block;
    }

	&__row{
		margin:0 -15px;
	}

	&__col{
		float: left;
	    padding: 0 15px;
	   	
	   	&--logo{
	   		text-align: center;
	    	width: 18%;
	   	}

	   	&--menu{
	   		width:34%;
	   	}

	   	&--links{
	   		width:33%;

	   		@media (max-width:$screen-max - 1px){
	   			width:28%;
	   		}


	   		@media (max-width:$screen-md - 1px){
	   			margin-top:38px;
	   		}

	   	}

	   	&--realization{
	   		width:15%;
	   		float:right;
	   		margin-top:-15px;

	   		@media (max-width:$screen-max - 1px){
	   			width:20%;
	   		}

	   		@media (max-width:$screen-md - 1px){
	   			margin-top:40px;
	   			text-align: center;
	   		}
	   	}

	   	@media (max-width:$screen-md - 1px){
	    		width:100%;
	    		float:none;
	    	}
	}

	&__menu{
		padding:0;
		list-style-type:none;
		 column-count: 2;
		 -webkit-column-count: 2;
		 -moz-column-count: 2;
		 -ms-column-count: 2;
		 -o-column-count: 2;

		 @media (max-width:$screen-md - 1px){
		 	    max-width: 531px;
    			margin: 0 auto;
    			margin-top: 60px;
		 }

		 @media (max-width:$screen-sm - 1px){
		 	margin-top:70px;
		 	column-count: 1;
		 	-webkit-column-count: 1;
			 -moz-column-count: 1;
			 -ms-column-count: 1;
			 -o-column-count: 1;
		 }
	}



	&__link{
		display:block;
		font-family: 'Alegreya', serif;
		text-align:center;
		color:$color2;
		font-size:16px;
		text-transform: uppercase;
		text-align:center;
		margin-right:68px;
		margin-bottom:20px;

		@media (max-width:$screen-max - 1px){
			font-size:14px;
		}

		@media (max-width:$screen-md - 1px){
			font-size:16px;
			margin-right:0;
		}



		&:hover,&:focus{
			color:$color2;
		}
	}

	&__img{
		margin-top:18px;
		max-width:100%;
		height:auto;
	}

	&__icon{
		display:inline-block;
		font-size:0;
		margin:0 10px;
	}

	&__text{
		font-size:12px;
		color:$color2;
		margin-left:15px;


		&--margin{
			margin-top:27px;
			margin-bottom:15px;

		}

		
			@media (max-width:$screen-md - 1px){
				margin-top:52px;
				margin-left:0;
				margin-right:0;
			}

		span{
			display:block;
			font-weight:bold;
			line-height:160%;
			text-transform: uppercase;
		}
	}

	&__optimalit{
		margin-top:7px;

		@media (max-width:$screen-md - 1px){
			margin-right:auto;
			margin-left:auto;
		}
	}
}