.contact{
	background:$gradient1;
	overflow:hidden;
	padding:0 15px;
	padding-top:98px;
	padding-bottom:41px;
	position:relative;

	@media (max-width:$screen-sm - 1px){
		padding-top:80px;
	}

	&__gradient{
		height:100%;
		width:100%;
		position:absolute;
		top:0;
		bottom:0;
		right:0;
		left:0;
		background:linear-gradient(to right, rgba(0,0,0,0), $gradient2, $gradient2, rgba(0,0,0,0));
		opacity:0;
		animation-name: opacityAnimation;
    	animation-duration: 3s;
    	animation-iteration-count:infinite;
	}

	&__header{
		margin:0;
		padding:0;
		font-family: 'Alegreya', serif;
		font-size:40px;
		color:$white;
		text-align:center;
		position:relative;
		opacity:0;

		@media (max-width:$screen-sm - 1px){
			font-size:31px;
		}

		&.animated{
			opacity:1;
		}

		&::before{
			content:'';
			display:block;
			@extend .sprite;
			@extend .sprite.badge-w;
			position:absolute;
			left:0;
			right:0;
			top:-52px;
			margin:auto;
		}

		&::after{
			content:'';
			display:block;
			height:2px;
			width:180px;
			background:$white;
			position:absolute;
			bottom:-20px;
			right:0;
			left:0;
			margin:auto;
		}

	}

	&__phone{
		background:rgba(0,0,0,0);
		border:4px solid $color7;
		border-radius:50px;
		max-width:610px;
		margin:0 auto;
		padding:8px 2px;
		text-align:center;
		margin-top:55px;
		position:relative;

		@media (max-width:$screen-sm - 1px){
			padding:5px 2px;
			border:2px solid $color7;
			max-width:305px;
		}

		&::before{
			content:'';
			display:block;
			@extend .sprite;
			@extend .sprite.phone-circle;
			position:absolute;
			left:15px;
			top:0;
			bottom:0;
			margin:auto;

			@media (max-width:$screen-sm - 1px){
				left:0;
				-webkit-transform: scale(0.65);
				   -moz-transform: scale(0.65);
				    -ms-transform: scale(0.65);
				     -o-transform: scale(0.65);
				        transform: scale(0.65);
			}
		}
	}

	&__link{
		color:$white;
		font-size:54px;
		font-weight:bold;
		display:block;

		@media (max-width:$screen-sm - 1px){
			font-size:26px;
		}

		&.animated{
			animation-duration: 3s !important;
		}

		
		&:hover,&:focus{
			color:$white;
			text-decoration:none;
		}



		span{
			font-weight:300;
		}
	}

	&__text{
		font-family: 'Alegreya', serif;
		font-size:22px;
		text-align: center;
		color:$white;
		margin:21px 0;
		margin-bottom:0;
		position:relative;
	}

	&__button{
		display:block;
		position:relative;
		border-radius:40px;
		max-width:278px;
		margin:0 auto;
		background:$color7;
		color:$white;
		text-align: center;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 22px;
    	margin-top: 11px;
    	padding: 18px 0;
		-webkit-transition: box-shadow 0.3s;
    	   -moz-transition: box-shadow 0.3s;
    	    -ms-transition: box-shadow 0.3s;
    	     -o-transition: box-shadow 0.3s;
    	        transition: box-shadow 0.3s;

    	&:hover,&:focus{
    		color:$white;
    		text-decoration:none;
    	}

    	&:hover{
    		box-shadow: 0 0 30px 3px $color7;
    	}
	}
}



@keyframes opacityAnimation {
    0% {opacity:0;}
    50% {opacity:1;}
    100% {opacity:0;}
}