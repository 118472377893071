.gallery{
	margin:0 auto;
	margin-top:90px;
	max-width:1260px;

	   @media (max-width:$screen-md - 1px){
			max-width:768px;	    	
	    }

	    @media (max-width:$screen-sm - 1px){
	    	max-width:350px;
	    }

	    &--page{
	    	margin-top:40px;
	    }

	&__row{
		margin:0 -2%;
		display: flex;
	    -ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	    -ms-flex-direction: row;
	    flex-direction: row;
	    -ms-flex-pack: start;
	    justify-content: center;
	    -ms-flex-align: stretch;
	    align-items: stretch;

	    @media (max-width:$screen-sm - 1px){
	    	margin:0;
	    }


	}

	&__col{
		padding:0 2%;
		float:left;
		width:33.33%;
		margin-bottom:6%;

		@media (max-width:$screen-md - 1px){
			width:50%;
		}

		@media (max-width:$screen-sm - 1px){
			width:100%;
			padding:0;
			float:none;
			margin-bottom:40px;
		}

		&--show{
			margin-bottom:7%;
		}
	}

	&__link{
		display:block;
		position:relative;
		

		&:hover{
			text-decoration:none;
			.gallery__description{
				background:$color7;
			}


		}


		&--show{
			
			@media (min-width:$screen-md){
			&:hover{
				.gallery__img{
					-webkit-transform: scale(1.1);
					   -moz-transform: scale(1.1);
					    -ms-transform: scale(1.1);
					     -o-transform: scale(1.1);
					        transform: scale(1.1);
				}
			}
		}
		}
	}

	&__img{
		max-width:100%;
		height:auto;
		box-shadow: 30px 20px 40px rgba(0, 0, 0, 0.2);
		-webkit-transition: transform 0.3s;
			   -moz-transition: transform 0.3s;
			    -ms-transition: transform 0.3s;
			     -o-transition: transform 0.3s;
			        transition: transform 0.3s;

		&--show{
			border:6px solid $white;
		}

		&--price{
			box-shadow:none;
			border:0;
		}
	}

	&__description{
		background:$color11;
		position:absolute;
		bottom:0;
		left:0;
		width:100%;
		height:81px;
		text-align:center;
		-webkit-transition: background 0.3s;
		   -moz-transition: background 0.3s;
		    -ms-transition: background 0.3s;
		     -o-transition: background 0.3s;
		        transition: background 0.3s;

	
		
		span{
			font-size:16px;
			color:$white;
			font-weight:600;
			text-align: center;
			position:absolute;
			top:0;
			bottom:0;
			left:0;
			right:0;
			margin:auto;
			display:inline-table;
			width:100%;
			padding:10px;

			
		}
	}

	&__header{
		font-size:22px;
		color:$color12;
		text-align:center;
		font-weight:600;
		margin: 0 auto;
    	margin-top: -5px;
    	line-height:140%;
    	max-width:585px;

	}


	&__center{
		text-align:center;
	}

	&__return{
		color:$color2;
		font-size:15px;
		font-weight:bold;
		text-transform: uppercase;
		display: inline-block;
   		margin-top: 32px;
   		margin-bottom:34px;
   		-webkit-transition: opacity 0.3s;
   		   -moz-transition: opacity 0.3s;
   		    -ms-transition: opacity 0.3s;
   		     -o-transition: opacity 0.3s;
   		        transition: opacity 0.3s;
   		position:relative;

   		&--bottom{
   			margin-top:-10px;
   			margin-bottom:0;

   			@media (max-width:$screen-sm - 1px){
   				margin-top:20px;
   			}
   		}

   		&:hover,&:focus{
   			color:$color2;
   			text-decoration:none;
   		}

   		&:hover{
   			opacity:0.8;
   		}

   		&::before{
   			content:'';
   			display:block;
   			width:13px;
   			height:13px;
   			border:3px solid rgba(0,0,0,0);
   			border-left:3px solid $color2;
   			border-bottom:3px solid $color2;
   			-webkit-transform: rotate(45deg);
   			   -moz-transform: rotate(45deg);
   			    -ms-transform: rotate(45deg);
   			     -o-transform: rotate(45deg);
   			        transform: rotate(45deg);
   			position:absolute;
   			top:0;
   			bottom:0;
   			left:-15px;
   			margin:auto;

   		}
	}

	&__photoTitle{
		font-size:16px;
		color:$color7;
		font-weight:600;
		text-align: center;
		line-height:160%;
		margin-top:19px;
	



	}

	


}