$default-color: #d23c1c;


$navbar-color: $default-color;
//primary-brand in bootstrap
$panel-color: $default-color;
//success-brand in bootstrap
$button-color: #ff9800;
//body color of primary-panel
$panel-body: #eaeaea;
// light text default color
$light-text: #555;

$screen-xs:                  480px !default;
$screen-sm:                  768px !default;
$screen-md:                  1025px !default;
$screen-mdx:                 1300px !default;
$screen-lg:                  1400px !default;
$screen-max:				1700px !default;

$white:#fff;
$black:#000;
$color1:#F4F3F9;
$color2:#B9B7CA;
$color3:#8B87A8;
$color4:#3C3C5D;
$color5:#3E4358;
$color6:#232336;
$color7:#36587B;
$color8:rgba(54,88,123,0.95);
$color9:#F9F9FB;
$color10:#2C3031;
$color11:rgba(54,88,123,0.8);
$color12:#35587A;
$color13:#8c0000;
$color14:#AC0641;
$color15:#e0e0e0;

$gradient1:#555572;
$gradient2:#88879E;
$gradient3:#DDDCE6;
