.bottomContact{
	position:relative;
	overflow-x:hidden;
	height:955px;
	z-index:-1;

	@media (max-width:$screen-md - 1px){
		height:auto;
	}

	&__container{
		padding-top:230px;
		width:50%;
		text-align:center;
		background:$color4;
		position:absolute;
		top:0;
		bottom:0;
		left:0;
		z-index:1;
		height:100%;
		-webkit-transition: left 0.5s;
		   -moz-transition: left 0.5s;
		    -ms-transition: left 0.5s;
		     -o-transition: left 0.5s;
		        transition: left 0.5s;

		&.active{
			left:-48%;
		}

		@media (max-width:$screen-md - 1px){
			width:100%;
			position:static;
			padding-top: 184px;
			padding-bottom:128px;
		}
	}


	&__header{
		margin:0;
		padding:0;
		font-family: 'Alegreya', serif;
		font-size:40px;
		color:$white;
		text-align:center;
		position:relative;
		opacity:0;
		margin-bottom:130px;

		&.animated{
			opacity:1;
		}

		&::before{
			content:'';
			display:block;
			@extend .sprite;
			@extend .sprite.badge-w;
			position:absolute;
			left:0;
			right:0;
			top:-52px;
			margin:auto;
		}

		&::after{
			content:'';
			display:block;
			height:2px;
			width:180px;
			background:$white;
			position:absolute;
			bottom:-20px;
			right:0;
			left:0;
			margin:auto;
		}

	}

	&__text{
		color:$color2;
		font-size:14px;
		font-weight:bold;
		text-align:center;
		margin-bottom:58px;
		line-height:140%;
		position:relative;

		a{
			display:block;
			color:$color2;

			&:hover,&:focus{
				color:$color2;
			}
		}

		&--marker{
			&::before{
				content:'';
				display:block;
				@extend .sprite;
				@extend .sprite.marker-w;
				position:absolute;
				top:-36px;
				right:0;
				left:0;
				margin:auto;

			}
		}
		&--calendar{
			&::before{
				content:'';
				display:block;
				@extend .sprite;
				@extend .sprite.calendar-w;
				position:absolute;
				top:-28px;
				right:0;
				left:0;
				margin:auto;

			}
		}
		&--phone{
			&::before{
				content:'';
				display:block;
				@extend .sprite;
				@extend .sprite.phones-w;
				position:absolute;
				top:-25px;
				right:0;
				left:0;
				margin:auto;

			}
		}
	}

	&__center{
		text-align: center;
	}

	&__button{
		display:inline-block;
		min-width:275px;
		text-align:center;
		border-radius:40px;
		padding:14px 20px;
		background:$white;
		color:$color7;
		font-weight:bold;
		text-transform: uppercase;
		margin-top:-10px;
		-webkit-transition: box-shadow 	0.3s;
		   -moz-transition: box-shadow 	0.3s;
		    -ms-transition: box-shadow 	0.3s;
		     -o-transition: box-shadow 	0.3s;
		        transition: box-shadow 	0.3s;

		&:hover,&:focus{
			color:$color7;
			text-decoration:none;
		}

		&:hover{
    		box-shadow: 0 0 20px 0px $white;
    	}
	}



	&__map{
		width:100%;
		height:100%;
		z-index:-1;

		@media (max-width:$screen-md - 1px){
			height:763px;
		}

		@media (max-width:$screen-sm - 1px){
			height:578px;
		}
	}

	&__mapButton{
		 font-size:0;
	    display:block;
	    width:49px;
	    height:49px;
	    background:$color4;
	    border-radius:100%;
	    position:absolute;
	    z-index:2;
	    top:0;
	    right:-25px;
	    bottom:0;
	    margin:auto;

	    @media (max-width:$screen-md - 1px){
	    	display:none;
	    }

	    &:hover{
	    	&::before{
	    		opacity:1;
	    	}
	    }

	    &.active{
	    	-webkit-transform: rotate(180deg);
	    	-moz-transform: rotate(180deg);
	    	-ms-transform: rotate(180deg);
	    	-o-transform: rotate(180deg);
	    	transform: rotate(180deg);
	    	
	    	
	    }


	   
	    &::before{
	    	opacity:0.6;
	    	-webkit-transition: opacity 0.3s;
	    	   -moz-transition: opacity 0.3s;
	    	    -ms-transition: opacity 0.3s;
	    	     -o-transition: opacity 0.3s;
	    	        transition: opacity 0.3s;
	    	content:'';
	    	display:block;
	    	width:13px;
	    	height:13px;
	    	border:3px solid rgba(0,0,0,0);
	    	border-bottom:3px solid $white;
	    	border-left:3px solid $white;
	    	-webkit-transform: rotate(45deg);
	    	   -moz-transform: rotate(45deg);
	    	    -ms-transform: rotate(45deg);
	    	     -o-transform: rotate(45deg);
	    	        transform: rotate(45deg);
	    	position:absolute;
	    	top:0;
	    	bottom:0;
	    	left:21px;
	    	margin:auto;

	    }
	}
}