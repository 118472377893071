.top{
	background:$color2;
	padding:8px 0;
	
	@media (max-width:$screen-sm - 1px){
		padding:2px 0;
	}

	&__container{
		@media (max-width:$screen-md - 1px){
			padding:0 10px;
		}
		@media (max-width:$screen-sm - 1px){
			padding:0 7px;
		}
	}


	&__col{
		float:left;
		width:50%;

		@media (max-width:$screen-md - 1px){
			width:35%;
		}

		&--right{
			float:right;
			text-align:right;

			@media (max-width:$screen-md - 1px){
				width:65%;
			}
		}

		@media (max-width:$screen-sm - 1px){
			width:50%;
		}
	}

	&__text{
		font-size:14px;
		color:$white;

		&--bold{
			font-weight:bold;
		}


		&--marker{
			padding-left:33px;
			position:relative;
					@media (max-width:$screen-sm - 1px){
						padding-left:28px;
					}

				&::before{
					content:'';
					display:block;
					@extend .sprite;
					@extend .sprite.marker;
					position:absolute;
					left:0;
					top:0;
					bottom:0;
					margin:auto;
				}
		}
	}

	&__icon{
		display:inline-block;
	}

	&__link{
		font-size:15px;
		color:$white;
		font-weight:bold;
		padding-left:27px;
		position:relative;
		letter-spacing: 0.5px;
		margin-left:9px;

				@media (max-width:$screen-sm - 1px){
					position: relative;
    				top: 10px;
				}

		&:hover,&:focus{
			color:$white;
		}

		&::before{
			content:'';
			display:block;
			@extend .sprite;
			@extend .sprite.phone;
			position:absolute;
			left:0;
			top:0;
			bottom:0;
			margin:auto;

		}

		&--home{
			@media (max-width:$screen-sm - 1px){
				display:none;
			}
		}
	}

	&__rezervation{
		@media (max-width:$screen-sm - 1px){
			display:none;
		}
	}

	&__city{
		@media (max-width:$screen-sm - 1px){
			display:block;
		}
	}
}