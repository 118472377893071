.paymentData{
  margin: 0 auto;
  width: 78%;
  padding-top: 10px;
  margin-bottom: 48px;

  @media (max-width:$screen-sm - 1px){
    width:100%;
  }

  &__row{
    display:flex;
    flex-direction: row;
    margin:0 -15px;
    margin-bottom: 27px;

    @media (max-width:$screen-sm - 1px){
      margin:0;
      margin-bottom: 27px;
    }


  }

  &__col{
    width:55%;
    padding:0 15px;

    @media (max-width:$screen-sm - 1px){
      padding:0;
    }
    &:first-child{
      padding-right:0;
      width:45%;
      @media (max-width:$screen-sm - 1px){
        padding-right:5px;
      }
    }





  }

  &__text{
    color:$color5;
    line-height:180%;
    font-size:15px;
    word-break: break-word;

    @media (max-width:$screen-sm - 1px){
      font-size:14px;
    }

    &--bold{
      font-weight:bold;
    }
  }
}