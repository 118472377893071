.ourSpecialists{
	padding:0 15px;
	padding-top:119px;
	padding-bottom:41px;
	background:$white;

			&--background{
				background:$color9;
				padding-top:60px;
			}

	@media (max-width:$screen-sm - 1px){
		padding-top:125px;
	}
	
		&__container{
			
			margin:0 auto;

			&--block{
				max-width:1400px;

				@media (max-width:$screen-md - 1px){
					max-width:768px;
				}

				@media (max-width:$screen-sm - 1px){
					max-width:350px;
				}
			}
		}

		&__header{
		margin:0;
		padding:0;
		font-family: 'Alegreya', serif;
		font-size:40px;
		color:$color5;
		text-align:center;
		position:relative;
		opacity:0;

		@media (max-width:$screen-sm - 1px){
			font-size:31px;
		}

		&.animated{
			opacity:1;
		}

		&::before{
			content:'';
			display:block;
			@extend .sprite;
			@extend .sprite.badge-c;
			position:absolute;
			left:0;
			right:0;
			top:-52px;
			margin:auto;
		}

		&::after{
			content:'';
			display:block;
			height:2px;
			width:180px;
			background:$color5;
			position:absolute;
			bottom:-20px;
			right:0;
			left:0;
			margin:auto;
		}

	}
	
	&__content{
		margin: 0 12%;
		margin-top:83px;

		@media (max-width:$screen-max - 1px){
			margin:0 5%;
			margin-top:83px;
		}

		@media (max-width:$screen-sm - 1px){
			margin: 0 10%;
    		margin-top: 61px;
		}
		
	}



	&__block{
		max-width:344px;
		text-align:center;
		margin:0 auto;
		display:block;
		text-decoration:none;
		overflow:hidden;

		&:hover,&:focus{
			text-decoration:none;
		}
		
		&:hover{
			.ourSpecialists__showMore{
				opacity:1;
			}
		}



	}

	&__img{
		max-width:100%;
		height:auto;
		border-radius:100%;
		width:auto !important;
		display: inline !important;
	}

	&__showMore{
		background:$color3;
		padding:10px;
		color:$white;
		font-size:14px;
        width:200px;
		margin:0 auto;
		margin-top: 14px;
		opacity:0;
		-webkit-transition: opacity 0.3s;
		-moz-transition: opacity 0.3s;
		-ms-transition: opacity 0.3s;
		-o-transition: opacity 0.3s;
		transition: opacity 0.3s;
		border-radius: 40px;

		@media (max-width:$screen-md - 1px){
			opacity:1;
		}
	}

	&__name{
		font-size:22px;
		color:$color7;
		font-weight:600;
		margin-top: 24px;

		@media (max-width:$screen-sm - 1px){
			margin-top:21px;
		}
	}

	&__text{
		color:$color5;
		font-size:14px;
		line-height:160%;
		margin-top: 14px;
	}


	.owl-nav{
		position:absolute;
		top:0;
		width:100%;
	}

	.owl-prev{
		cursor:pointer;
		position:absolute;
		left:17px;
		top:88px;
		font-size:0 !important;
		width:30px;
		height:30px;
		border:2px solid rgba(0,0,0,0)!important;
		border-left:2px solid $color2 !important;
		border-top:2px solid $color2 !important;
		-webkit-transform: rotate(-45deg);
		   -moz-transform: rotate(-45deg);
		    -ms-transform: rotate(-45deg);
		     -o-transform: rotate(-45deg);
		        transform: rotate(-45deg);
		-webkit-transition: border-color 0.3s;
		   -moz-transition: border-color 0.3s;
		    -ms-transition: border-color 0.3s;
		     -o-transition: border-color 0.3s;
		        transition: border-color 0.3s;
		outline:none;
		        &:hover{
					border-left:2px solid $color7 !important;
					border-top:2px solid $color7 !important;
		        }

		        @media (max-width:$screen-md - 1px){
		        	left:-23px;
		        }
	}

	.owl-next{
		cursor:pointer;
		position:absolute;
		right:17px;
		top:88px;
		font-size:0 !important;
		width:30px;
		height:30px;
		border:2px solid rgba(0,0,0,0)!important;
		border-right:2px solid $color2 !important;
		border-top:2px solid $color2 !important;
		-webkit-transform: rotate(45deg);
		   -moz-transform: rotate(45deg);
		    -ms-transform: rotate(45deg);
		     -o-transform: rotate(45deg);
		        transform: rotate(45deg);
		-webkit-transition: border-color 0.3s;
		   -moz-transition: border-color 0.3s;
		    -ms-transition: border-color 0.3s;
		     -o-transition: border-color 0.3s;
		        transition: border-color 0.3s;
		outline:none;
		        &:hover{
					border-right:2px solid $color7 !important;
					border-top:2px solid $color7 !important;
		        }

		         @media (max-width:$screen-md - 1px){
		        	right:-23px;
		        }
	}

	&__row{
		display: flex;
	    -ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	    -ms-flex-direction: row;
	    flex-direction: row;
	    -ms-flex-pack: start;
	    justify-content: center;
	    -ms-flex-align: stretch;
	    align-items: stretch;
	    margin:0 -15px;
	    margin-top:105px;

	    @media (max-width:$screen-sm - 1px){
	    	margin:0;
	    	margin-top:80px;
	    }
	}

	&__col{
		width:33.33%;
		padding:0 15px;
		margin-bottom:120px;

		@media (max-width:$screen-md - 1px){
			width:50%;
		}

		@media (max-width:$screen-sm - 1px){
			width:100%;
			margin-bottom:80px;
		}
	}
}