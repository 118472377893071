html, body{
	font-family: 'Open Sans', sans-serif;
	/* font-family: 'Alegreya', serif; */
  
    @media (max-width:$screen-md - 1px){
          overflow-x:hidden;
    }
}

.overflow{
    overflow:hidden;
}

.clear{
	clear:both;
}


.main-container{
	  max-width: 1675px;
	  margin: 0 auto;
	  padding: 0 15px;

}

.pulse {
    animation-iteration-count: infinite !important;
}


.scrollTop{
    font-size:0;
    width:49px;
    height:49px;
    background:$white;
    border-radius:100%;
    position:fixed;
    right:24px;
    bottom:-80px;
    box-shadow:0 0 40px 0 rgba(0,0,0,0.5);
    opacity:0.7;
    z-index:10;
    -webkit-transition: opacity 0.3s, bottom 0.3s;
       -moz-transition: opacity 0.3s, bottom 0.3s;
        -ms-transition: opacity 0.3s, bottom 0.3s;
         -o-transition: opacity 0.3s, bottom 0.3s;
            transition: opacity 0.3s, bottom 0.3s;

    @media (max-width:$screen-sm - 1px){
        right:10px;
    }

    &.active{
    	bottom:80px;
    }

    &:hover{
    	opacity:1;
    }

    &::before{
    	content:'';
    	display:block;
    	width:13px;
    	height:13px;
    	border:3px solid rgba(0,0,0,0);
    	border-top:3px solid $color7;
    	border-left:3px solid $color7;
    	-webkit-transform: rotate(45deg);
    	   -moz-transform: rotate(45deg);
    	    -ms-transform: rotate(45deg);
    	     -o-transform: rotate(45deg);
    	        transform: rotate(45deg);
    	position:absolute;
    	right:0;
    	left:0;
    	top:21px;
    	margin:auto;

    }
}

.main{
    position:relative;
    z-index:1;
}

.pagination-block{
    text-align:center;
}

.pagination{
    &__li{
        &.active{
            .pagination__link{
                background:$color7;
                border-color:$color7;
                color:$white !important;

                &:hover{
                    background:$color7;
                    border-color:$color7;
                }
            }
        }
    }

    &__link{
        color:$color7 !important;
    }
}


.alert{
    padding: 20px;
    text-align: center;
    font-size: 20px;
    background:none;
    @media (max-width:$screen-md - 1px){
        font-size:15px;
    }

    &-success{
        border:2px solid $color6;
        color:$color6;
    }

    &-danger{
        text-align:left;
        border:2px solid $color13;
        color:$color13;
    }
}