.couponContact{
  max-width:525px;
  margin:0 auto;
  padding-bottom:100px;
  margin-top:65px;

  @media (max-width:$screen-md - 1px){
    padding-bottom:50px;
  }

  @media (max-width:$screen-sm - 1px){
    max-width:350px;
  }

  &__title{
    color:$color5;
    font-family: 'Alegreya', serif;
    text-align: center;
    font-size:30px;
    line-height:120%;
  }

  &__row{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin:20px 0;

    @media (max-width:$screen-sm - 1px){
      display:block;
    }
  }

  &__link{
    font-size:20px;
    color:$color7;
    font-weight:bold;
    padding-left: 45px;
    position:relative;
    margin: 0 30px;
    &:hover,&:focus{
      text-decoration: underline;
      color:$color7;
    }

    &::before{
      content:'';
      display:block;
      width:34px;
      height:35px;
      background: url('/media/default/purplePhone.png')no-repeat;
      position:absolute;
      left:0;
      top:0;
      bottom:0;
      margin:auto;
    }

  }

  &__block{

    @media (max-width:$screen-sm - 1px){
        text-align:center;
      margin:20px 0;
    }
  }
}