.whyUs{
	padding:0 15px;
	padding-top:93px;
	padding-bottom:41px;
	background:linear-gradient($white, $gradient3);
	position:relative;
	padding-bottom:330px;
		
		@media (max-width:$screen-sm - 1px){
			padding-top: 124px;
			padding-bottom: 379px;
		}



	&__container{
		max-width:1265px;
		margin:0 auto;
		position:relative;
		z-index:1;
		padding-bottom:40px;
	}

	&__header{
		margin:0;
		padding:0;
		font-family: 'Alegreya', serif;
		font-size:40px;
		color:$color5;
		text-align:center;
		position:relative;
		opacity:0;

		@media (max-width:$screen-sm - 1px){
			font-size:31px;
		}

		&.animated{
			opacity:1;
		}

		&::before{
			content:'';
			display:block;
			@extend .sprite;
			@extend .sprite.badge-c;
			position:absolute;
			left:0;
			right:0;
			top:-52px;
			margin:auto;
		}

		&::after{
			content:'';
			display:block;
			height:2px;
			width:180px;
			background:$color5;
			position:absolute;
			bottom:-20px;
			right:0;
			left:0;
			margin:auto;
		}
	}


	&__content{
		display: -ms-flexbox;
	    display: flex;
	    -ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	    -ms-flex-direction: row;
	    flex-direction: row;
	    -ms-flex-pack: start;
	    justify-content: center;
	    -ms-flex-align: stretch;
	    align-items: stretch;
	    margin:0 auto;
	    margin-top:92px;

	    @media (max-width:$screen-md - 1px){
	    	max-width:768px;
	    	margin-top:80px;
	    }

	    @media (max-width:$screen-sm - 1px){
	    	margin-top: 58px;
	    }

	   	&.animation{
			.whyUs__block{
				animation-name:fadeIn;
			}
		}
	}



	&__block{
		display:block;
		width:25%;
		margin:0 2%;
		text-align:center;
		float:left;
		margin-bottom:26px;
		animation-duration: 2s;
		animation-fill-mode: both;
		opacity:0;

		&:nth-child(1){
			animation-delay:0s;
		}
		&:nth-child(2){
			animation-delay:0.25s;
		}
		&:nth-child(3){
			animation-delay:0.5s;
		}
		&:nth-child(4){
			animation-delay:0.75s;
		}
		&:nth-child(5){
			animation-delay:1s;
		}

		&:nth-child(6){
			animation-delay:1.25s;
		}

		@media (max-width:$screen-md - 1px){
			width:45%;
			margin:0;
			margin-bottom:28px;
		}

		@media (max-width:$screen-sm - 1px){
			width:100%;
			float:none;
			margin:0;
			margin-bottom:28px;
		}

		&:hover,&:focus{
			text-decoration:none;
			.whyUs{
				&__outsideCircle{
					border-color:$color7;
				}

				&__circle{
					background:$color7;
				}
			}
		}
	}





	&__outsideCircle{
		margin:0 auto;
		width:89px;
		height:89px;
		padding:12px;
		position:relative;
		padding:5px;
		border:4px solid rgba(0,0,0,0);
		border-radius:100%;
		-webkit-transition: border-color 0.3s;
		   -moz-transition: border-color 0.3s;
		    -ms-transition: border-color 0.3s;
		     -o-transition: border-color 0.3s;
		        transition: border-color 0.3s;

	}

	&__circle{
		width:60px;
		height:60px;
		background:$color3;
		border-radius:100%;
		position:absolute;
		top:0;
		right:0;
		left:0;
		bottom:0;
		margin:auto;
		-webkit-transition: background 0.3s;
		   -moz-transition: background 0.3s;
		    -ms-transition: background 0.3s;
		     -o-transition: background 0.3s;
		        transition: background 0.3s;
	}

	&__icon{
		position:absolute;
		top:0;
		right:0;
		left:0;
		bottom:0;
		margin:auto;
	}

	&__text{
		color:$color5;
		font-weight:600;
		font-size:18px;
		margin-top:10px;
	}

	&__button{
		display: inline-block;
	    background: $color7;
	    border-radius: 40px;
	    font-weight: bold;
	    color: $white;
	    font-size: 15px;
	    min-width: 389px;
	    text-align: center;
	    margin: 0 auto;
	    margin-top: 70px;
	    padding: 14px 21px;
	    text-transform: uppercase;
		-webkit-transition: box-shadow 0.3s;
    	   -moz-transition: box-shadow 0.3s;
    	    -ms-transition: box-shadow 0.3s;
    	     -o-transition: box-shadow 0.3s;
    	        transition: box-shadow 0.3s;

    	 @media (max-width:$screen-md - 1px){
    	 	margin-top:80px;
    	 }

    	 @media (max-width:$screen-sm - 1px){
    	 	margin-top: 33px;
			max-width: 270px;
			min-width: 0;
			font-size: 14px;
			padding: 3px 35px;
    	 }

    	&:hover,&:focus{
    		color:$white;
    		text-decoration:none;
    	}

    	&:hover{
    		box-shadow: 0 0 30px 3px #36587B;
    	}

	}
	&__center{
		text-align:center;
	}
	&__bottom{
		width:100%;
		height:516px;
		background:url('/media/default/bottom-bg.png')no-repeat;
		background-size:cover;
		background-position:left;
		position:absolute;
		left:0;
		bottom:0;
		z-index:0;

		@media (max-width:$screen-sm - 1px){
			background-position-x: -215px;
    		background-position-y: 25px;
		}
	}
}