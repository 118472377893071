.menu{
	background:$white;
	padding:16px 0;
	position:sticky;
	position:-webkit-sticky;
	top:0;
	z-index:100;
	box-shadow: 0 0 25px 0 rgba(0,0,0,0.2);

	@media (max-width:$screen-md - 1px){
		position:fixed;
		top:0;
		bottom:0;
		left:0;
		right:0;
		width:100%;
		background:$color8;
		text-align:center;
		z-index:100;
		display:none;
		padding-bottom:0;
		box-shadow:none;
	}

	&__container{
		@media (max-width:$screen-md - 1px){
			height:100%;
			padding:0;
			overflow-y:scroll;
			height:100%;
			padding-bottom:20px;
		}
	}

	&__list{
		padding:0;
		margin:0 9%;
		max-width:1345px;
		list-style-type:none;

		@media (max-width:$screen-md - 1px){
			margin-top:20%;
		}

		@media  (max-device-width: $screen-md - 1px) and (orientation: landscape) {
			margin-top:40px;
		}

		@media (max-width:$screen-sm - 1px){
			margin:0;
			margin-top:60px;
		}

		@media  (max-device-width: $screen-sm - 1px) and (orientation: landscape) {
			margin-top:0px;
		}
	}

	&__li{
		float:left;
		margin-bottom:5px;
		&:first-child{
			padding-left:0;
		}

		&:last-child{
			.menu__link{
				padding-right:0;
				&::after{
					display:none;
				}
			}
		}

		@media (max-width:$screen-md - 1px){
			float:none;
			padding:0;	
		}

		@media  (max-device-width: $screen-sm - 1px) and (orientation: landscape) {
			&:last-child{
				.menu__link{
					margin-bottom:0;
				}
			}
		}

		&.active{
			.menu__link{
				text-decoration:underline;
			}
		}
	}

	&__link{
		display:block;
		font-family: 'Alegreya', serif;
		text-transform: uppercase;
		font-size:16px;
		color:$color5;
		padding:0 25px;
		position:relative;

		&:hover,&:focus{
			color:$color5;
		}

		@media (max-width:$screen-max - 1px){
			padding:0 15px;
			font-size:14px;
		}

		@media (max-width:$screen-md - 1px){
			font-size:22px;
			color:$white;
			text-align:center;
			margin-bottom:30px;

			&:hover,&:focus{
				color:$white;
			}
		}

		@media  (max-device-width: $screen-sm - 1px) and (orientation: landscape) {
			font-size:16px;
			margin-bottom:15px;
			padding:0 3px;
		}

		&::after{
			content:'';
			display:block;
			position:absolute;
			right:0;
			top:0;
			bottom:0;
			margin:auto;
			width:5px;
			height:5px;
			border-radius:100%;
			background:$color5;

			@media (max-width:$screen-md - 1px){
				right:0;
				left:0;
				bottom:-60px;
				background:$white;
			}

			@media  (max-device-width: $screen-sm - 1px) and (orientation: landscape) {
				bottom:-35px;
			}
		}

		
	}

	&__icon{
		display:block;
		float:right;
		margin-left:20px;
		margin-top:-5px;
		font-size:0;

		@media (max-width:$screen-md - 1px){
			display:none;
		}
	}

	&__mobileIcon{
		display:none;

		@media (max-width:$screen-md - 1px){
			display:block;
			font-size:0;
			display:inline-block;
			margin:0 25px;
		}
	}

	
}

.menuButton{
	width:49px;
	height:49px;
	border-radius:100%;
	background:$white;
	padding: 17px 10px;
	position:fixed;
	top: 89px;
    right: 24px;
	z-index:101;
	display:none;

	-webkit-transition: top 0.3s;
	   -moz-transition: top 0.3s;
	    -ms-transition: top 0.3s;
	     -o-transition: top 0.3s;
	        transition: top 0.3s;

	@media (max-width:$screen-md - 1px){
		display:block;
	}

	@media (max-width:$screen-sm - 1px){
    	right: 10px;
	}

	&.active{
		box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.5);

		.menuButton__text{
			opacity:0;
		}

		@media (max-width:$screen-md - 1px){
			top: 40px;
		}

		@media (max-width:$screen-sm - 1px){
			top: 25px;
		}


	}

	&__span{
		display:block;
		width:22px;
		height:3px;
		background:$color7;
		margin:0 auto;
		margin-bottom:3px;
		-webkit-transition: opacity 0.3s, transform 0.3s;
			   -moz-transition: opacity 0.3s, transform 0.3s;
			    -ms-transition: opacity 0.3s, transform 0.3s;
			     -o-transition: opacity 0.3s, transform 0.3s;
			        transition: opacity 0.3s, transform 0.3s;
	}

	&.open{

		background:none;
		box-shadow:none;

		@media (max-width:$screen-md - 1px){
			top:40px;
		}

		@media (max-width:$screen-sm - 1px){
			top:25px;
		}

		.menuButton__span{
			background:$white;
			
			&--top{
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				margin:auto;
				-webkit-transform: rotate(45deg);
				   -moz-transform: rotate(45deg);
				    -ms-transform: rotate(45deg);
				     -o-transform: rotate(45deg);
				        transform: rotate(45deg);
			}

			&--center{
				opacity:0;
			}

			&--bottom{
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				margin:auto;
				-webkit-transform: rotate(-45deg);
				   -moz-transform: rotate(-45deg);
				    -ms-transform: rotate(-45deg);
				     -o-transform: rotate(-45deg);
				        transform: rotate(-45deg);
			}
		}

		.menuButton__text{
			opacity:1;
			color:$white;
		}
	}

	&__text{
		font-family: 'Alegreya', serif;
		font-size:16px;
		color:$color5;
		text-transform: uppercase;
		position:absolute;
		top: -15px;
    	right: 2px;
    	-webkit-transition: opacity 0.3s, color 0.3s;
    	   -moz-transition: opacity 0.3s, color 0.3s;
    	    -ms-transition: opacity 0.3s, color 0.3s;
    	     -o-transition: opacity 0.3s, color 0.3s;
    	        transition: opacity 0.3s, color 0.3s;
	}
}