.header{
	background:$white;
	padding-top:9px;
	text-align:center;
	position:relative;
	z-index:101;

	@media (max-width:$screen-md - 1px){
		padding:12px 0;
		z-index:1;
	}

	@media (max-width:$screen-sm - 1px){
		padding: 7px 0;
    	padding-bottom: 13px;
	}

	&__container{
		position:relative;
	}


	&__img{
		max-width:100%;
		height:auto;

		@media (max-width:$screen-sm - 1px){
			max-width:63%;
		}
	}


}