.basketSection{
  position:absolute;
  right: 24px;
  top: 24px;
  display:flex;
  flex-direction: row;


  @media (min-width:$screen-md) {
    &.fixed {
      position: fixed;
      top: 100px;
      right: 25px;
      display: flex;
      align-items: center;
      justify-content: center;

      .basketSection__button {
        margin: 0;
        margin-right: 12px;
      }

      .basketSection__icon{
        border-radius: 100%;
        box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.5);
      }

      .basketSection__button{
        box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.5);
      }
    }
  }
  @media (max-width:$screen-md - 1px){
    position:fixed;
    top:auto;
    right:0;
    left:0;
    bottom:5px;
    margin:auto;
    display:flex;
    align-items: center;
    justify-content: center;
  }


  @media (max-width:$screen-sm - 1px){
    justify-content: flex-start;
    margin-left:10px;
  }




  &__basket{
    position:relative;
    width:49px;
    height:49px;

  }

  &__amount{
    background:$color14;
    font-weight:bold;
    color:$white;
    font-size:15px;
    display:flex;
    align-items: center;
    justify-content: center;
    width:21px;
    height:21px;
    border-radius:100%;
    position:absolute;
    right:-10px;
    bottom:0;
  }

  &__button{
    width:205px;
    margin:0 auto;
    font-size:15px;
    color:$white;
    background:$color14;
    text-transform: uppercase;
    text-align: center;
    padding:13px 5px;
    -webkit-transition: box-shadow 0.3s;
    -moz-transition: box-shadow 0.3s;
    -ms-transition: box-shadow 0.3s;
    -o-transition: box-shadow 0.3s;
    transition: box-shadow 0.3s;
    border-radius:50px;
    font-weight:bold;
    cursor:pointer;
    margin-right: 12px;

    @media (max-width:$screen-md - 1px){
      margin:0;
      margin-right: 12px;
      box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.5);
    }

    @media (max-width:$screen-sm - 1px){
      width:170px;
      font-size:13px;
    }

    &:hover,&:focus{
      color:$white;
      text-decoration:none;
    }

      &:hover{
        box-shadow: 0 0 30px 3px $color14;
      }
    }

  &__icon{
    @media (max-width:$screen-md - 1px){
      border-radius: 100%;
      box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.5);
    }
  }

}