.page{
	background:$color9;
	padding: 137px 0;
	padding-bottom:118px;
	overflow-x:hidden;

	@media (max-width:$screen-sm - 1px){
		padding-top:45px;
		padding-bottom:90px;
	}

	&__container{
		max-width:1360px;

		&--small{
			max-width:1000px;
		}
	}

	&--gallery{
		padding-bottom:80px;
	}

	&--default{
		padding-bottom:10px;
	}

	&__header{
		color:$color5;
		font-family: 'Alegreya', serif;
		text-align: center;
		font-size:40px;
		position:relative;
		margin:0;
		margin-bottom:65px;
	

		@media (max-width:$screen-sm - 1px){
			font-size:31px;
		}

		

		&::before{
			content:'';
			display:block;
			@extend .sprite;
			@extend .sprite.badge-c;
			position:absolute;
			right:0;
			left:0;
			top:-48px;
			margin:auto;

			@media (max-width:$screen-sm - 1px){
				display:none;
			}
		}

		&::after{
			content:'';
			display:block;
			height:2px;
			width:180px;
			position:absolute;
			right:0;
			left:0;
			bottom:-18px;
			margin:auto;
			background:$color5;
		}
	}

	&__row{
		margin:0 -25px;
		margin-bottom:65px;
		display: flex;
	    -ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	    -ms-flex-direction: row;
	    flex-direction: row;
	    -ms-flex-pack: start;
	    justify-content: center;
	    -ms-flex-align: stretch;
	    align-items: stretch;

		&--double{
			margin-left:-15px;
			margin-right:-15px;
		}
		
		&--noMargin{
			margin-right:0;
			margin-left:0;
		}

	    &--smallMargin{
	    	margin-right: -10px;
	    	margin-left:-10px;
	    }

	    @media (max-width:$screen-md - 1px){
			margin:0;
			margin-bottom:40px;
			display:block;
		}
	}

	&__col{
		float:left;
		padding:25px;
		display: flex;
	    -ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	    -ms-flex-direction: column;
	    flex-direction: column;
	    -ms-flex-pack: start;
	    justify-content: center;
	    -ms-flex-align: stretch;
	    align-items: stretch;

		&--half{
			width:50%;
		}

		&--right{
			float:right;
		}

		&--narrower{
			width:46.85%;
		}

		&--wider{
			width:49%;
		}

		&--photo{
			width:48%;
		}

		&--smallPadding{
			padding:10px;
		}

		&--max{
			width:100%;
			float:none;
			padding:0;

		}

		&--price{
			width:50%;
			padding:0 15px;
		}

		@media (max-width:$screen-md - 1px){
			float:none;
			width:100%;
			padding:0;
		}
	}

	&__text{
		font-size:14px;
		line-height:160%;
		color:$color5;
		margin-top:8px;

		a{
			color:$color5;
		}

		span{
			color:$color5 !important;
		}
		
		&--left{
			padding-left:10px;

			@media (max-width:$screen-md - 1px){
				padding:0;
			}
		
			
		}

		@media (max-widthpa:$screen-md - 1px){
				margin-bottom:50px;
			}
	}

	&__photo{
		display:block;
		text-align:center;


		@media (max-width:$screen-md - 1px){
			text-align:center;
			margin:50px 0;
		}
	}

	&__img{
		border:10px solid $white;
		max-width:100%;
		height:auto;
		box-shadow: 30px 20px 40px rgba(0, 0, 0, 0.2);
		-webkit-transition: transform 0.3s;
			   -moz-transition: transform 0.3s;
			    -ms-transition: transform 0.3s;
			     -o-transition: transform 0.3s;
			        transition: transform 0.3s;

			        &--price{
			        	box-shadow:none;
			        	border:0;
			        }
	}

	&__content{
		max-width:615px;
		margin:0 auto;
	}

	&__center{
		text-align:center;
	}

	&__button{
		border:0;
		outline:0;
		display:inline-block;
		background:$color7;
		min-width: 270px;
		color:$white;
		font-size:15px;
		text-transform: uppercase;
		font-weight:bold;
		text-align: center;
		margin:0 auto;
		margin-top:20px;
		padding: 13px 20px;
    	border-radius: 40px;
    	-webkit-transition: box-shadow 0.3s;
    	   -moz-transition: box-shadow 0.3s;
    	    -ms-transition: box-shadow 0.3s;
    	     -o-transition: box-shadow 0.3s;
    	        transition: box-shadow 0.3s;

    	&:hover,&:focus{
    		color:$white;
    		text-decoration:none;
    	}

    	&:hover{
    		box-shadow: 0 0 30px 3px $color7;
    	}

    	&--error{
    		margin-top:30px;
    		margin-bottom:80px;
    	}

	}

	&__ytIiframe{
		border:0;
		width:100%;
		height:400px;


		&--center{
			width: 50%;
			margin: 0 auto;
		}


		@media (max-width:$screen-md - 1px){
			width:50%;
			height: 30vw;
			margin:0 auto;
			display:block;
		}

		@media (max-width:$screen-md - 1px){
			width:90%;
			height: 49vw;
			margin:0 auto;
			display:block;
		}
	}

}