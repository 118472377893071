.getToKnowUs{
	padding:118px 15px;

	@media (max-width:$screen-md - 1px){
		padding-bottom:63px;
	}

	@media (max-width:$screen-sm - 1px){
		padding-top:89px;
		padding-bottom:47px;
	}

	&__container{
		max-width:1265px;
		margin:0 auto;
	}

	&__header{
		color:$color5;
		font-family: 'Alegreya', serif;
		text-align: center;
		font-size:40px;
		position:relative;
		margin:0;
		opacity:0;

		@media (max-width:$screen-sm - 1px){
			font-size:31px;
		}

		&.animated{
			opacity:1;
		}

		&::before{
			content:'';
			display:block;
			@extend .sprite;
			@extend .sprite.badge-c;
			position:absolute;
			right:0;
			left:0;
			top:-48px;
			margin:auto;
		}

		&::after{
			content:'';
			display:block;
			height:2px;
			width:180px;
			position:absolute;
			right:0;
			left:0;
			bottom:-18px;
			margin:auto;
			background:$color5;
		}
	}

	&__row{
		margin:0 -15px;
		margin-top:72px;

		@media (max-width:$screen-md - 1px){
		    max-width: 615px;
		    margin: 0 auto;
		    margin-top: 40px;
		}

		@media (max-width:$screen-sm - 1px){
			max-width:100%;
		}
	}

	&__col{
		padding:0 15px;
		float:left;
		width:50%;

		@media (max-width:$screen-md - 1px){
			padding:0;
			float:none;
			width:100%;
		}
	}

	&__text{
		color:$color5;
		line-height:160%;
		font-size:14px;
		margin-top:10px;
		padding-right:40px;

		@media (max-width:$screen-md - 1px){
			padding-right:0;
		}
	}


	&__indicators{
		bottom:-62px !important;

		@media (max-width:$screen-sm - 1px){
			bottom: -72px !important;
		}
	}
	&__li{
			width:14px !important;
			height:14px !important;
			padding:0 !important;
			border:0 !important;
			background:$color2 !important;
			border-radius:100%;
			margin:0 11px !important;

			@media (max-width:$screen-sm - 1px){
				margin:0 17px !important;
			}

			&.active{
				width:14px !important;
				height:14px !important;
				margin:0 11px !important;
				padding:0 !important;
				border:0 !important;
				background:$color7 !important;
					
				@media (max-width:$screen-sm - 1px){
					margin:0 17px !important;
				}

			}
	}

	&__inner{
		border:10px solid $white;
		box-shadow: 40px 30px 50px rgba(0, 0, 0, 0.2);

		@media (max-width:$screen-sm - 1px){
			border:5px solid $white;
		}
	}

	&__img{
		width:100%;
	}

	&__center{
		text-align:center;
	}

	&__button{
		display:inline-block;
		background:$color7;
		min-width:390px;
		color:$white;
		font-size:15px;
		text-transform: uppercase;
		font-weight:bold;
		text-align: center;
		margin:0 auto;
		margin-top: 117px;
		padding: 13px 20px;
    	border-radius: 40px;
    	-webkit-transition: box-shadow 0.3s;
    	   -moz-transition: box-shadow 0.3s;
    	    -ms-transition: box-shadow 0.3s;
    	     -o-transition: box-shadow 0.3s;
    	        transition: box-shadow 0.3s;

    	&:hover,&:focus{
    		color:$white;
    		text-decoration:none;
    	}

    	&:hover{
    		box-shadow: 0 0 30px 3px $color7;
    	}

    	@media (max-width:$screen-sm - 1px){
    	    max-width: 272px;
    	    min-width:0;
    	    padding: 5px 20px;
    	    line-height: 120%;
    	    margin-top:103px;
    	}
	}

	&__slider{
		@media (max-width:$screen-md - 1px){
			margin-top:37px;
		}

		@media (max-width:$screen-sm - 1px){
			margin-left:-5px;
			margin-right:-5px;
		}
	}
}