.coupon{
  padding-top:50px;
  &__header{
    @extend .page__header;
  }


  &__content{
    padding:40px 75px;
    padding-bottom:32px;
    max-width:615px;
    margin:30px auto;
    background:$white;
    box-shadow: 30px 20px 40px rgba(0, 0, 0, 0.2);

    @media (max-width:$screen-sm - 1px){
      padding-left:15px;
      padding-right:15px;
      max-width:350px;
    }

    &--thanks{
      padding-bottom:55px;
    }
  }

  &__title{
    color:$color5;
    font-family: 'Alegreya', serif;
    text-align: center;
    font-size:30px;
    margin:0 auto;
    max-width:442px;
    line-height:120%;

    &--marginTop{
      margin-top:17px;
    }
  }

  &__form{
    margin-top: 23px;
  }

  &__block{
    display:flex;
    align-items: center;
    justify-content: center;
    position:relative;
    margin-bottom: 30px;

    @media (max-width:$screen-sm - 1px){

    }
  }

  &__input{
    border:1px solid $color2;
    height:39px;
    font-weight:bold;
    color:$color7;
    font-size:18px;
    width:320px;
    padding:0 5px;
    outline:0;
    margin-right: 7px;




    @media (max-width:$screen-sm - 1px){
      width: 165px;
    }

    &--basket{
      width:220px;
      margin:0;
      margin-bottom: 12px;
      color:$color5;
      font-size:15px;
      font-weight: 400;

      @media (max-width:$screen-sm - 1px){
        width:100%;
      }
    }

    &--textarea{
      width:100%;
      min-width:100%;
      max-width:100%;
      height:114px;
      min-height:114px;
      max-height:114px;
      padding:8px;
      margin-bottom:-15px;
      padding-bottom:0;
    }

    &--full{
      width:100%;
    }


    &::placeholder{
      color:$color3;
      font-size:15px;
      font-weight:400;

      @media (max-width:$screen-sm - 1px){
        font-size:13px;
      }
    }

    &:focus{
      border:2px solid $color14;
    }
  }

  &__span{
    color:$color5;
    font-weight:600;
    font-size:16px;
  }

  &__trash{
    color:$color3;
    font-size:12px;
    text-decoration:underline;
    cursor:pointer;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -ms-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
    position:absolute;
    right:0;
    top: 13px;
    bottom:0;
    margin:auto;
    font-family: "Open Sans";

    &--basket{
      position:static;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .fa{
        text-decoration: none;
      }
    }

    .fa{
      display:inline-block;
      margin-right:5px;
      font-size: 13px;
    }

    &:hover{
      color:$color7;
    }
  }

  &__text{
    color:$color5;
    font-weight:600;
    font-size:15px;
    text-align:center;
    a{
      color:$color7;
      text-decoration: underline;

      &:hover{
        color:$color5;
      }
    }

    &--light{
      color:$color3;
      font-size:16px;

    }

    &--option{
      text-align:left;
      font-size:15px;
      line-height:140%;
      color:$color5;
      font-weight: 400;
      margin:15px 0;
    }

    &--left{
      text-align: left;
    }

    &--info{
      font-size:15px;
      color:$color5;
      font-weight:400;
      margin:33px 0;
    }

    &--bold{
      color:$color7;
      font-weight:bold;
      font-size:18px;
      margin:30px 0;
      margin-top:73px;

      @media (max-width:$screen-sm - 1px){
        margin-top:50px;
      }
    }
  }

  &__button{
    width:205px;
    margin:0 auto;
    font-size:15px;
    color:$white;
    text-transform: uppercase;
    text-align: center;
    padding:13px 5px;
    -webkit-transition: box-shadow 0.3s;
    -moz-transition: box-shadow 0.3s;
    -ms-transition: box-shadow 0.3s;
    -o-transition: box-shadow 0.3s;
    transition: box-shadow 0.3s;
    border-radius:50px;
    font-weight:bold;
    cursor:pointer;
    display:block;

    &:hover,&:focus{
      color:$white;
      text-decoration:none;
    }


    &--add{
      background:$color7;
      margin-top:37px;
      margin-bottom:10px;
      width: 170px;
      font-size: 13px;
      padding: 10px 5px;

      &:hover{
        box-shadow: 0 0 30px 3px $color7;
      }
    }

    &--next{
      background:$color14;
      margin-top:9px;
      margin-bottom:10px;
      display:block;
      border:0;
      outline:0;

      &:hover{
        box-shadow: 0 0 30px 3px $color14;

      }
    }

    &--margin{
      margin-top:50px;
    }

    &--disabled{
      background:$color15;
      &:hover,&:focus{
        box-shadow:none;
      }
    }


    &--home{
      width:287px;
    }
  }

  &__dotpay{
    display:block;
    margin:0 auto;
    margin-top: 34px;
  }

  &__info{
    max-width:325px;
    margin:0 auto;
    font-size:12px;
    color:$color5;
    text-align:center;
    line-height:140%;
    margin-top:14px;
    margin-bottom:12px;
  }

  &__link{
    font-size:12px;
    color:$color5;
    text-decoration:underline;
    display:inline-block;
    &:hover,&:focus{
      color:$color7;
    }
  }

  &__center{
    text-align:center;
  }

  &__basket{
    margin-top:-10px;
    border-bottom:1px solid $color2;
    padding-bottom: 24px;
    margin-bottom: 29px;

    &--noBorder{
      border:0;
      padding-bottom:10px;
    }
  }

  &__basketBlock{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

  }

  &__basketHeader{
    color:$color5;
    font-family: 'Alegreya', serif;
    font-size:30px;

    &--label{
      font-family: "Open Sans";
      color:$color5;
      font-size:18px;
      font-weight:600;
    }
  }

  &__value{
    color:$color13;
    font-size:18px;
    font-weight:bold;
  }

  &__label{
    text-transform: uppercase;
    font-size:12px;
    font-weight:bold;
    color:$color7;
    margin-bottom: 6px;

    &--option{
      display:block;
      color:$color3;
      text-decoration:underline;
      -webkit-transition: color 0.3s;
      -moz-transition: color 0.3s;
      -ms-transition: color 0.3s;
      -o-transition: color 0.3s;
      transition: color 0.3s;

      &.active{
        color:$color7 !important;
      }

      &:hover,&:focus{
        color:$color3;
      }

      &:hover{
        color:$color7;
      }
    }
  }

  &__cancel{
    display:none;
    font-size:12px;
    color:$color2;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -ms-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
    text-decoration:underline;
    position:relative;
    top:-3px;
    &:hover,&:focus{
      color:$color7;
    }
  }

  &__row{
    display:flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__agreement{
    font-size:10px;
    color:$color10;
    line-height:120%;
    font-weight:400;
  }

  &__more{
    font-weight:bold;
    color:$color10;
    display:inline-block;
    margin-left:3px;
    &:hover,&:focus{
      color:$color10;
      text-decoration:underline;
    }
  }

  &__moreText{
    display:none;
  }

  &__option{
    display:none;
  }


  &__empty{
    margin-bottom:50px;
    text-align:center;
    font-size:16px;
    color:$color14;
  }


  &__return{
    color: $color2;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    display: inline-block;
    transition: opacity 0.3s;
    position: relative;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
    margin-top:30px;

    &::before{
      content: '';
      display: block;
      width: 13px;
      height: 13px;
      border: 3px solid transparent;
      border-left: 3px solid $color2;
      border-bottom: 3px solid $color2;
      transform: rotate(45deg);
      position: absolute;
      top: 0;
      bottom: 0;
      left: -15px;
      margin: auto;
    }

    &:hover,&:focus{
      opacity:0.8;
      text-decoration: none;
      color:$color2;
    }
  }


}