.recommended{
	padding:70px 15px;
	background:$white;

	@media (max-width:$screen-md - 1px){
		padding:60px 15px;
	}

	@media (max-width:$screen-sm - 1px){
		padding:30px 0;

	}

	&__container{
		margin: 0 5%;

		@media (max-width:$screen-md - 1px){
			margin:0 10%;
		}

		@media (max-width:$screen-sm - 1px){
			margin: 0px 14%;
		}
	}

	&__link{
		position:relative;
		display:block;
		padding:0 100px;
		border-right:1px solid $color2;
		height:179px;
		text-align:center;

		@media (max-width:$screen-md - 1px){
			border:0;
		}

		@media (max-width:$screen-sm - 1px){
			height:auto;
			padding:0;
			margin-top:20px;
			height:100%;
		}
		

		&:hover{
			.recommended__img{
				filter:none;
			}
		}
	}

	.owl-item{
		&:last-child{
			.recommended__link{
				border-right:0 !important;
			}
		}
	}

	&__img{
		display:block;
		max-width:100%;
		max-height:114px;
		width:auto !important;
		margin:0 auto;
		position:absolute;
		top:0;
		bottom:0;
		left:0;
		right:0;
		margin:auto;
		filter:sepia(80%) hue-rotate(175deg) saturate(50%);
		-webkit-filter:sepia(80%) hue-rotate(175deg) saturate(50%);

		@media (max-width:$screen-sm - 1px){
			
			position:static;
			max-width: 100% !important;
    		height: auto !important;
    		max-height:53px;
		}
	}

	.owl-nav{
		width:100%;
		

		&::before{
			content:'';
			display:block;
			position:absolute;
			right:-1px;
			top:0;
			bottom:0;
			height:100%;
			width:2px;
			background:$white;
		}

		&::after{
			content:'';
			display:block;
			position:absolute;
			left:-1px;
			top:0;
			bottom:0;
			height:100%;
			width:2px;
			background:$white;
		}
	}

	.owl-prev{
		cursor:pointer;
		position:absolute;
		left:-40px;
		top:36%;
		font-size:0 !important;
		width:30px;
		height:30px;
		border:2px solid rgba(0,0,0,0)!important;
		border-left:2px solid $color2 !important;
		border-top:2px solid $color2 !important;
		-webkit-transform: rotate(-45deg);
		   -moz-transform: rotate(-45deg);
		    -ms-transform: rotate(-45deg);
		     -o-transform: rotate(-45deg);
		        transform: rotate(-45deg);
		-webkit-transition: border-color 0.3s;
		   -moz-transition: border-color 0.3s;
		    -ms-transition: border-color 0.3s;
		     -o-transition: border-color 0.3s;
		        transition: border-color 0.3s;
		outline:none;
		        &:hover{
					border-left:2px solid $color7 !important;
					border-top:2px solid $color7 !important;
		        }
	}

	.owl-next{
		cursor:pointer;
		position:absolute;
		right:-40px;
		top:36%;
		font-size:0 !important;
		width:30px;
		height:30px;
		border:2px solid rgba(0,0,0,0)!important;
		border-right:2px solid $color2 !important;
		border-top:2px solid $color2 !important;
		-webkit-transform: rotate(45deg);
		   -moz-transform: rotate(45deg);
		    -ms-transform: rotate(45deg);
		     -o-transform: rotate(45deg);
		        transform: rotate(45deg);
		-webkit-transition: border-color 0.3s;
		   -moz-transition: border-color 0.3s;
		    -ms-transition: border-color 0.3s;
		     -o-transition: border-color 0.3s;
		        transition: border-color 0.3s;
		outline:none;
		        &:hover{
					border-right:2px solid $color7 !important;
					border-top:2px solid $color7 !important;
		        }
	}
}