.couponSteps{
  padding-top:13px;

    &__row{
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

  &__block{
    margin: 0 47px;
    text-align:center;
    min-width: 85px;

    @media (max-width:$screen-sm - 1px){
      margin:0 20px;
    }


    &.active{
        .couponSteps{
          &__circle {

            background: $color7;
            &::before {
              content: '';
              display: block;
              width: 44px;
              height: 44px;
              border: 1px solid $color7;
              position: absolute;
              left: -5px;
              top: -5px;
              margin: auto;
              border-radius: 100%;

            }
          }

          &__text{
            color:$color7;
          }
        }
    }

    &.made{
      .couponSteps {
        &__circle {

          background: $color7;
        }

        &__text{
          color:$color7;
        }
      }
    }


  }

  &__circle{
    background:$color2;
    color:$white;
    text-align:center;
    font-family: "Open Sans";
    width:34px;
    height:34px;
    border-radius:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:17px;
    font-weight:bold;
    position:relative;
    margin:0 auto;

  }

  &__text{
    text-transform: uppercase;
    font-size:12px;
    font-weight:bold;
    text-align:center;
    color:$color2;
    margin-top:12px;
  }

  &__arrow{
    position:absolute;
    top:0;
    bottom: 0px;
    right: -95px;
    margin:auto;
    width:65px;
    height:1px;
    background:$color7;

    &::before{
      content:'';
      display:block;
      width:10px;
      height:1px;
      background:$color7;
      position:absolute;
      right:0;
      top: -2px;
      -webkit-transform: rotate(25deg);
      -moz-transform: rotate(25deg);
      -ms-transform: rotate(25deg);
      -o-transform: rotate(25deg);
      transform: rotate(25deg);
    }

    &::after{
      content:'';
      display:block;
      width:10px;
      height:1px;
      background:$color7;
      position:absolute;
      right:0;
      top: 2px;
      -webkit-transform: rotate(-25deg);
      -moz-transform: rotate(-25deg);
      -ms-transform: rotate(-25deg);
      -o-transform: rotate(-25deg);
      transform: rotate(-25deg);
    }

    @media (max-width:$screen-sm - 1px){
      display:none;
    }
  }

}