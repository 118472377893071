.price{
	
	&__container{
		max-width:1265px;
		margin:0 auto;

		@media (max-width:$screen-md - 1px){
			max-width:768px;
		}

		@media (max-width:$screen-sm - 1px){
			max-width:100%
		}
	}

	&__row{
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	    -ms-flex-direction: row;
	    flex-direction: row;
	    -ms-flex-pack: start;
	    justify-content: center;
	    -ms-flex-align: stretch;
	    align-items: stretch;
	    margin:0 -23px;
	    margin-top:130px;


	    @media (max-width:$screen-sm - 1px){
	    	margin:0;
	    	margin-top:50px;
	    }

	    &--margin{
	    	margin-top:55px;
	    }
	}

	&__col{
		float:left;
		width:20%;
		padding:0 23px;
		margin-bottom:65px;

		@media (max-width:$screen-mdx - 1px){
			width:25%;
		}
		

		@media (max-width:$screen-md - 1px){
	    	width:33.33%;
	    	margin-bottom:50px;
	    }

		@media (max-width:$screen-sm - 1px){
	    	padding:0;
			float: left;
			width: 225px;
			padding: 0 10px;
			margin-bottom:30px;
	    }
	}

	&__block{
		position:relative;
		display:block;


		@media (min-width:$screen-md){
			&:hover{
				.price__text{
					background:$color7;
				}
			}
		}

		&.active{
			opacity:0.3;
			cursor:default;

			&:hover{
				.price__text{
					background:$color11;
				}
			}
		}
	}

	&__img{
		max-width:100%;
		height:auto;
		box-shadow: 30px 20px 40px rgba(0, 0, 0, 0.2);
	}

	&__text{
		background: $color11;
	    position: absolute;
	    bottom: 0;
	    left: 0;
	    width: 100%;
		display:flex;
	    text-align: center;
	    -webkit-transition: background 0.3s;
	       -moz-transition: background 0.3s;
	        -ms-transition: background 0.3s;
	         -o-transition: background 0.3s;
	            transition: background 0.3s;

	    span{
	    	font-size: 15px;
		    color: $white;
		    font-weight: 600;
		    text-align: center;
		    display: flex;
		    width: 100%;
		    padding: 10px;
			justify-content: center;
			align-items: center;
	    }
	}

	&__header{
		margin:0;
		text-align: center;
		color:$color7;
		font-weight: 600;
		font-size:30px;
		margin-top:80px;
	}



	&__center{
		text-align:center;
	}

	&__return{
		color:$color2;
		font-size:15px;
		font-weight:bold;
		text-transform: uppercase;
		display: inline-block;
   		margin-top: 32px;
   		margin-bottom:34px;
		-webkit-transition: color 0.3s;
		-moz-transition: color 0.3s;
		-ms-transition: color 0.3s;
		-o-transition: color 0.3s;
		transition: color 0.3s;
   		position:relative;

   		&--bottom{
   			margin-top:-10px;
   			margin-bottom:0;

   			@media (max-width:$screen-sm - 1px){
   				margin-top:20px;
   			}
   		}

   		&--margin{
   			margin:0;
   		}

   		&:hover,&:focus{
   			color:$color2;
   			text-decoration:none;
   		}

   		&:hover{
   			color:$color3;

			&::before{
				border-left:3px solid $color3;
				border-bottom:3px solid $color3;
			}
   		}

   		&::before{
   			content:'';
   			display:block;
   			width:13px;
   			height:13px;
   			border:3px solid rgba(0,0,0,0);
   			border-left:3px solid $color2;
   			border-bottom:3px solid $color2;
   			-webkit-transform: rotate(45deg);
   			   -moz-transform: rotate(45deg);
   			    -ms-transform: rotate(45deg);
   			     -o-transform: rotate(45deg);
   			        transform: rotate(45deg);
			-webkit-transition: border-color 0.3s;
			-moz-transition: border-color 0.3s;
			-ms-transition: border-color 0.3s;
			-o-transition: border-color 0.3s;
			transition: border-color 0.3s;
   			position:absolute;
   			top:0;
   			bottom:0;
   			left:-15px;
   			margin:auto;

   		}
	}


	&__showMore{
		text-align:center;
		font-size:30px;
		color:$color7;
		font-weight:600;
		margin-top:75px;
	}

}