.priceList{




	&__border{
		border-bottom:1px solid $color3;
		-webkit-transition: background 0.3s;
		   -moz-transition: background 0.3s;
		    -ms-transition: background 0.3s;
		     -o-transition: background 0.3s;
		        transition: background 0.3s;
		
		&.active{
			background:$color3;

			.priceList{
				&__price{
					color:$white;
				}

				&__name{
					color:$white;
				}
			}


		}

		  &--big{
	    	border-bottom:2px solid $color4;
	    	margin-top:35px;
	    	


	    }





	}

	&__container{
		max-width:1275px;
		margin:0 auto;
		padding:0 15px;
	}

	&__row{
		display: -ms-flexbox;
	    display: flex;
	    -ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	    -ms-flex-direction: row;
	    flex-direction: row;
	    -ms-flex-pack: start;
	    justify-content: center;
	    -ms-flex-align: stretch;
	    align-items: stretch;
	    margin:0 -15px;
	    padding:6px 0;
	    min-height:43px;

	    @media (max-width:$screen-sm - 1px){
	    	padding-top: 11px;
	    	margin:0;

	    }

	  
	}


	&__col{
		float:left;
		padding:0 15px;
		width:33.33%;
		display: -ms-flexbox;
	    display: flex;
	    -ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	    -ms-flex-direction: column;
	    flex-direction: column;
	    -ms-flex-pack: start;
	    justify-content: center;
	    -ms-flex-align: stretch;
	    align-items: stretch;
	    position:relative;

		&--center{
			text-align: center;
		}

		&--right{
			text-align: right;
		}

		@media (max-width:$screen-sm - 1px){
			float:none;
			display:block;
			width:100%;
			padding:0;
			text-align: left;

			&--right{
				text-align:right;
			}
		}
	}

	&__name{
		color:$color5;
		font-size:16px;
		font-weight: 600;
		-webkit-transition: color 0.3s;
		   -moz-transition: color 0.3s;
		    -ms-transition: color 0.3s;
		     -o-transition: color 0.3s;
		        transition: color 0.3s;
		line-height:110%;

		@media (max-width:$screen-md - 1px){
			font-size:14px;
		}

		&--big{
			font-family: 'Alegreya', serif;
			font-size:30px;
			color:$color4;
			font-weight:400;

			@media (max-width:$screen-md - 1px){
				font-size:25px;
			}
		}
	}

	&__price{
		font-size:22px;
		color:$color7;
		font-weight:bold;

		-webkit-transition: color 0.3s;
		   -moz-transition: color 0.3s;
		    -ms-transition: color 0.3s;
		     -o-transition: color 0.3s;
		        transition: color 0.3s;

		@media (max-width:$screen-md - 1px){
			font-size:19px;
		}

		      @media (max-width:$screen-sm - 1px){
		      	text-align: left;
		      }
	}

	&__button{
	    width: 122px;
	    text-align: left;
	    border-radius: 40px;
	    padding: 7px 5px;
	    background: $color7;
	   	padding-left:17px;
	    color: $white;
	    font-size: 11px;
	    font-weight: bold;
	    position: relative;
	    text-transform: uppercase;
	    transition: box-shadow 0.3s;
	    display: inline-block;
		-webkit-transition: box-shadow 	0.3s, background 0.3s, color 0.3s;
		   -moz-transition: box-shadow 	0.3s, background 0.3s, color 0.3s;
		    -ms-transition: box-shadow 	0.3s, background 0.3s, color 0.3s;
		     -o-transition: box-shadow 	0.3s, background 0.3s, color 0.3s;
		        transition: box-shadow 	0.3s, background 0.3s, color 0.3s;
		position:absolute;
		right:0;
		top:0;
		bottom:0;
		margin:auto;
		height:30px;

		@media (max-width:$screen-lg - 1px){
			right:10px;
		}

		@media (max-width:$screen-sm - 1px){
			position:relative;
			margin:10px 0;
		}
	


		&:hover,&:focus{
			color:$white;
			text-decoration:none;
		}

		&:hover{
    		box-shadow: 0 0 20px 0px $color7;
    	}

    	&::before{
    		content:'';
    		display:block;
    		width:8px;
    		height:8px;
    		border:2px solid rgba(0,0,0,0);
    		border-right:2px solid $white;
    		border-bottom:2px solid $white;
    		-webkit-transform: rotate(45deg);
    		   -moz-transform: rotate(45deg);
    		    -ms-transform: rotate(45deg);
    		     -o-transform: rotate(45deg);
    		        transform: rotate(45deg);
    		-webkit-transition: transform 0.3s;
    		   -moz-transition: transform 0.3s;
    		    -ms-transition: transform 0.3s;
    		     -o-transition: transform 0.3s;
    		        transition: transform 0.3s;
    		position:absolute;
    		right:12px;
    		top:9px;
    	}

    	&.active{
    		background:$white;
    		color:$color7;

	    	&:hover,&:focus{
				color:$color7;
				text-decoration:none;
			}

			&:hover{
	    		box-shadow: 0 0 20px 0px $white;
	    	}

	    	&::before{
	    		top:13px;
	    		border-right:2px solid $color7;
    			border-bottom:2px solid $color7;
	    		-webkit-transform: rotate(-135deg);
	    		   -moz-transform: rotate(-135deg);
	    		    -ms-transform: rotate(-135deg);
	    		     -o-transform: rotate(-135deg);
	    		        transform: rotate(-135deg);
	    	}

    	}
	}

	&__content{
		padding:50px 0;
		padding-bottom:0;
		background:$white;
		display:none;

		@media (max-width:$screen-sm - 1px){
			padding:15px 0;
		}
	}

	&__return{
		&::before{
			left:-20px;
			top:7px;
			-webkit-transform: rotate(-225deg);
			   -moz-transform: rotate(-225deg);
			    -ms-transform: rotate(-225deg);
			     -o-transform: rotate(-225deg);
			        transform: rotate(-225deg);
		}
	}
}