.banner{
	position:relative;

	@media (max-width:$screen-sm - 1px){
		background:$color7;
	}

	&__img{
		width:100%;
	}

	&__nav{
		position:absolute;
		right:83px;
		bottom:20px;

		@media (max-width:$screen-md - 1px){
			right: 30px;
    		bottom: 15px;
		}

		@media (max-width:$screen-sm - 1px){
			right: 18px;
    		bottom: auto;
    		top: 25vw;
		}
	}

	&__indicators{
		bottom:22px !important;

		@media (max-width:$screen-md - 1px){
			display:none;
		}
	}

	&__content{
		position:absolute;
		top:0;
		right:0;
		left:0;
		bottom:0;
		margin:auto;
		max-width:732px;
		text-align:center;
		width:100%;
		display: flex;
	    -ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	    -ms-flex-direction: column;
	    flex-direction: column;
	    -ms-flex-pack: start;
	    justify-content: center;
	    -ms-flex-align: stretch;
	    align-items: stretch;

		@media (max-width:$screen-sm - 1px){
			position:static;
			background:$color7;
			max-width:100%;
		}
	}

	&__text{
		font-family: 'Alegreya', serif;
		font-size:93px;
		color:$white;
		text-transform: uppercase;
		line-height:120%;
		opacity:0;
		animation-delay: 0.5s !important;

		@media (max-width:$screen-md - 1px){
			font-size:62px;
		}

		@media (max-width:$screen-sm - 1px){
			font-size:33px;
			animation:none !important;
		}

		&.animated{
			opacity:1;
		}

		&--small{
			font-family: 'Open Sans', sans-serif;
			font-size:25px;
			text-transform: none;
			animation-delay: 1s !important;

			@media (max-width:$screen-md - 1px){
				font-size:17px;
				max-width:500px;
				margin:0 auto;
			}

			@media (max-width:$screen-sm - 1px){
				margin-top: 8px;
				padding:0 5px;
				padding-top:3px;
				padding-bottom:20px;
				font-size:13px;

			}
		}
	}

	&__button{
		display: inline-block;
	    background: $color7;
	    border-radius: 40px;
	    font-weight: bold;
	    min-width:191px;
	    color: $white;
	    font-size: 15px;
	    text-align: center;
	    margin: 0 auto;
	    margin-top: 41px;
	    padding: 14px 21px;
	    text-transform: uppercase;
		-webkit-transition: box-shadow 0.3s;
    	   -moz-transition: box-shadow 0.3s;
    	    -ms-transition: box-shadow 0.3s;
    	     -o-transition: box-shadow 0.3s;
    	        transition: box-shadow 0.3s;
		animation-delay: 1.5s !important;
		opacity:0;



		&.animated{
			opacity:1;
		}

    	&:hover,&:focus{
    		color:$white;
    		text-decoration:none;
    	}

    	&:hover{
    		box-shadow: 0 0 30px 3px $color7;
    	}

    	@media (max-width:$screen-sm - 1px){
    		background:$white;
    		color:$color7;
    		margin-top:15px;
    		animation:none !important;

	    	&:hover,&:focus{
	    		color:$color7;
	    		text-decoration:none;
	    	}

	    	&:hover{
	    		box-shadow: 0 0 30px 3px $white;
	    	}

    	}
	}

	&__arrow{
		display:inline-block;
		width:35px;
		height:35px;
		border:3px solid rgba(0,0,0,0);
		opacity:0.3;
		-webkit-transition: opacity 0.3s;
		   -moz-transition: opacity 0.3s;
		    -ms-transition: opacity 0.3s;
		     -o-transition: opacity 0.3s;
		        transition: opacity 0.3s;

		&:hover{
			opacity:1;
		}

		
		&--next{
			border-right:3px solid $color7;
			border-top:3px solid $color7;
			-webkit-transform: rotate(45deg);
		   -moz-transform: rotate(45deg);
		    -ms-transform: rotate(45deg);
		     -o-transform: rotate(45deg);
		        transform: rotate(45deg);
		}

		&--prev{
			border-left:3px solid $color7;
			border-top:3px solid $color7;
			margin-right:8px;
			-webkit-transform: rotate(-45deg);
			   -moz-transform: rotate(-45deg);
			    -ms-transform: rotate(-45deg);
			     -o-transform: rotate(-45deg);
			        transform: rotate(-45deg);
		}
	}

	&__li{
		width:14px !important;
		height:14px !important;
		padding:0 !important;
		border:0 !important;
		background:$white !important;
		border-radius:100%;
		margin:0 9px !important;
		border:2px solid $color7 !important;

		&.active{
			width:14px !important;
			height:14px !important;
			margin:0 9px !important;
			padding:0 !important;
			border:0 !important;
			background:$color7 !important;
		}
	}
}